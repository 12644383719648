@use '@angular/material' as mat;

// https://material.angular.io/guide/theming#palettes
$my-app-primary-palette: mat.define-palette(mat.$grey-palette, 900);
$my-app-accent-palette: mat.define-palette(mat.$green-palette, 600);

$primary-color: mat.get-color-from-palette($my-app-primary-palette);
$accent-color: mat.get-color-from-palette($my-app-accent-palette);

/* COLORS: */
$white: #ffffff;
$black: #000000;

$grey-100: #f5f5f5;
$grey-200: #e6e6e6;
$grey-300: #cccccc;
$grey-400: #68666d;
$grey-500: #555555;
$grey-600: #333333;
$grey-700: #2d2c30;

$blue-100: #add8e6;
$blue-200: #0fb0eb;
$blue-300: #1e90ff;
$blue-400: #007bff;

$green: #39823c;
$red: #d33c33;
$orange: orange;
$purple: purple;

/* SPACERS: */
$spacer-1: 0.25rem;
$spacer-2: 0.5rem;
$spacer: 1rem;
$spacer-4: 1.5rem;
$spacer-5: 2rem;
$spacer-6: 3rem;

/* TYPOGRAPHY: FONT WEIGHTS */
$font-weight-300: 300;
$font-weight-400: 400;
$font-weight-700: 700;

/* MEDIA QUERIES: */
@mixin for-phone-only {
  @media (max-width: 599px) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: 600px) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: 900px) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: 1200px) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: 1800px) {
    @content;
  }
}
