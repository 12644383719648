@use '@angular/material' as mat;
@import 'variables';
@include mat.core();

$my-app-theme: mat.define-light-theme(
  (
    color: (
      primary: $my-app-primary-palette,
      accent: $my-app-accent-palette
    )
  )
);

@include mat.all-component-themes($my-app-theme);

* {
  letter-spacing: normal;
}

:root {
  //font-size: 62.5%;
  --red: #{$red};
  --green: #{$green};
}

html,
body {
  //font-size: 1.6rem;
  height: 100%;
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.button-row {
  display: flex;
  gap: 1.6rem;
  justify-content: center;
  flex-wrap: wrap;
}

.pointer,
a,
i {
  cursor: pointer;
}

.scrollbar {
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: $grey-100;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey-500;
    border-radius: 5px;
  }
}

.sticky-h2 {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background-color: $white;
  z-index: 10;
}

.button-border {
  border: 1px solid $grey-500 !important;
}

.mat-mdc-snack-bar-container {
  &.mat-snackbar-warn,
  &.mat-snackbar-success {
    --mdc-snackbar-supporting-text-color: white;
    --mat-snack-bar-button-color: white;
  }

  &.mat-snackbar-warn {
    --mdc-snackbar-container-color: var(--red);
  }

  &.mat-snackbar-success {
    --mdc-snackbar-container-color: var(-green);
  }
}

.mat-mdc-button-base {
  &.mat-warn {
    --mdc-filled-button-container-color: var(--red);
  }
}
